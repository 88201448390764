.my__bread_crumb {
  display: flex;
  align-items: center;
  position: relative;

  .back__button {
    position: absolute;
    left: -36px;
    top: -4px;
    cursor: pointer;
  }

  * {
    color: black !important;
    font-family: Poppins;
    font-size: 12px;
  }
}
