.modal__add__payment {
  form {
    margin: 24px auto 0px auto;
    max-width: 400px;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    color: #000000;
    margin: 10px 0 18px 0;
  }

  .btn {
    margin-top: 24px;
    height: 32px;
    width: 120px;
    border-radius: 0;
    border-color: #535252;
    color: #535252;
  }

  .btn_confirm {
    background-color: #535252;
    color: white;
  }
}
