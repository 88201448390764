.order__page {
  h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    color: #535252;
  }

  .input__section {
    margin-top: 22px;
    margin-bottom: 35px;
    .ant-input-prefix {
      height: 26px;
      margin-right: 8px;
    }
  }

  .ant-tabs-nav {
    margin-bottom: 0px;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab:nth-child(1) {
    padding: 11px 27px;
  }
  .ant-tabs-tab {
    padding: 11px 15px;
    > div {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #4a5463 !important;
    }
  }
  .ant-tabs-tab-active {
    background-color: #e7eaef;
  }
}

.confirm_layout {
  > div:nth-child(1) {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    button {
      padding: 0px;
    }
  }

  .ant-steps {
    max-width: 580px;
    .ant-steps-item-content {
      width: 120px;
    }
    .ant-steps-item-subtitle {
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      color: #979797 !important;
    }
    .ant-steps-item-finish .ant-steps-item-icon {
      background: #979797;
      .ant-steps-icon {
        color: white;
      }
    }
    .ant-steps-item-active .ant-steps-item-icon {
      background: #979797;
      .ant-steps-icon {
        color: #979797;
        -webkit-transition: color 200ms linear;
        -ms-transition: color 200ms linear;
        transition: color 200ms linear;
      }
    }
    .ant-steps-item-icon {
      background: #eeeeee;
      border: none !important;
      .ant-steps-icon {
        color: #eeeeee;
      }
    }
    .ant-steps-item-tail {
      padding: 2px 0px 2px 12px;
    }
    .ant-steps-item-tail::after {
      height: 5px;
      background-color: #eeeeee !important;
    }
  }
}

.confirm__order__page {
  .body {
    margin-top: 27px;
    .info {
      background-color: #eeeeee;
      padding: 40px !important;
      color: #535252;

      .box__info {
        background: #ffffff;
        border-radius: 2px;
        .box__info__head {
          padding: 13px 25px;
          border-bottom: 1px solid #e5e5e5;
        }
        .box__info__body {
          padding: 13px 25px 20px 25px;
          font-size: 12px;
        }
      }
    }
    .details {
      color: #535252;
      padding: 0px 30px !important;
      .address {
        padding: 17px;
        border-bottom: 1px solid #e5e5e5;
        div:nth-child(2) {
          background: #f1f1f1;
          padding: 20px;
          border-radius: 2px;
          margin-top: 18px;
          font-size: 12px;
        }
      }
      .order_detail {
        margin-top: 5px;
        padding: 17px;
      }
    }
    .btn_control {
      padding: 0 20px;
      button {
        width: 100%;
        height: 35px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        border: none;
        border-radius: 2px;
      }
      .cancel_btn {
        color: #535252;
        border-color: #ffffff;
      }
      .confirm_btn {
        background: #535252;
        color: #ffffff;
        border-color: #535252;
      }
    }
  }

  .input__order__confirm {
    border: none;
    background-color: #f1f1f1;
    width: 100%;
    font-size: 12px;
    color: #535252;
  }

  .radio__order__confirm {
    margin-top: 12px;
    font-size: 14px;

    .ant-radio-checked .ant-radio-inner {
      border-color: #535252 !important ;
      background-color: #535252;
    }

    .ant-radio-checked .ant-radio-inner::after {
      background-color: white;
    }

    .ant-radio:hover .ant-radio-inner {
      border-color: #535252;
    }

    .ant-space-item {
      .detail {
        font-size: 12px;
        color: #535252;
      }
    }
  }

  .add__payment {
    border: none;
    border-radius: 2px;
    width: 100%;
    background-color: #f1f1f1;
    border-color: #f1f1f1;
    margin-top: 12px;
    color: #535252;
    font-size: 12px;
  }
}

.confirm__payment__page {
  .body {
    margin-top: 27px;

    > .title_page {
      text-align: center;
      color: #535252;
      > div:nth-child(1) {
        font-size: 18px;
      }
      > div:nth-child(2) {
        font-size: 12px;
      }
    }

    .subtitle_section {
      background: #f9f9f9;
      padding: 21px 28px;
      color: #000000;
      font-size: 18px;
    }

    .box__detail {
      color: #58595b;
      padding: 13px 20px;
      border: 1px solid #58595b10;
      .box__head {
      }
      .box__body {
        border-top: 1px solid #97979710;
        padding: 13px;
        margin-top: 13px;
        > div:not(:first-child) {
          margin-top: 9px;
        }
      }
    }

    .confirm_btn {
      color: white;
      width: 246px;
      height: 40px;
      background: #535252;
      border-color: #535252;
      border-radius: 2px;
    }
  }
}
