.shipping__page {
  h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    color: #535252;
  }

  .input__section {
    margin-top: 22px;
    margin-bottom: 35px;
    .ant-input-prefix {
      height: 26px;
      margin-right: 8px;
    }
  }

  .ant-tabs-nav {
    margin-bottom: 0px;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab:nth-child(1) {
    padding: 11px 27px;
  }
  .ant-tabs-tab {
    padding: 11px 15px;
    > div {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #4a5463 !important;
    }
  }
  .ant-tabs-tab-active {
    background-color: #e7eaef;
  }
}
