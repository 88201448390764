.shop__page {
  h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    color: #535252;
  }

  .input__section {
    margin-top: 22px;
    margin-bottom: 35px;
    height: 100px;
    background: #fafafa;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 45px;
    > div:nth-child(1) {
      flex: 0 0 200px;
    }
    > div:nth-child(2) {
      flex: 1 1 auto;
    }
    .ant-input-prefix {
      height: 26px;
      margin-right: 8px;
    }
  }

  .btn_add_section {
    text-align: right;
    > button {
      height: 40px;
      width: 141px;
      background-color: #535252;
      color: white;
      font-size: "12px";
      font-family: "Poppins";
      border: none;
      border-radius: 0;
      border-color: #535252;
    }
  }
}

.shop__form__page {
  .shop__form__input {
    margin-top: 24px;
    background: #fbfbfb;
    padding: 44px 100px;
    > .ant-col {
      background: #ffffff;
      padding: 24px;
    }

    .ant-upload-select-picture-card,
    .ant-upload-list-picture-card-container {
      width: 64px;
      height: 64px;
      background: white;
    }

    .ant-upload-list-item-actions {
      > a:nth-child(1) {
        display: none;
      }
    }

    .ant-upload-list-item {
      padding: 2px;
    }

    .uploadButton {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .item__tag {
      .ant-select-selection-item {
        font-family: Poppins;
        font-weight: normal;
        font-size: 13px;
        color: #080c16;
        display: flex;
        align-items: center;
        justify-items: space-between;
        margin-right: 10px;
        height: 28px !important;
      }
    }

    .head-title-prod {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.14px;
      color: #000000;
    }

    h2 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.14px;
      color: #1b1d21;
      margin: 0;
    }
    p {
      margin-bottom: 20;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.14px;
      color: #464a53;
    }
    .prod__feature {
      color: #464a53;
      font-size: 12px;
    }
    .prod__information {
      font-family: Roboto;
      font-style: normal;
      font-size: 12px;
      letter-spacing: 0.14px;
      color: #080c16;
    }
    .text-w-500 {
      font-weight: 500;
    }

    // .ant-select-selection-placeholder {
    //   font-size: 14px !important;
    // }

    .add__more {
      background-color: #ffffff;
      border-color: #979797;
      box-sizing: border-box;
      border-radius: 2px;
      color: #979797;
      height: 35px;
      margin-top: 12px;
    }
  }
  .submit__section {
    margin-top: 74px;

    .submit__btn {
      border-radius: 2px;
      width: 170px;
      height: 35px;
    }

    .cancel__btn {
      background-color: #ffffff;
      border-color: #535252;
      color: #535252;
    }

    .save__btn {
      background-color: #535252;
      border-color: #535252;
      color: #ffffff;
    }
  }
  .information__product {
    position: relative;
    .btn_del {
      position: absolute;
      right: 0;
      top: -24px;
    }
  }
}
