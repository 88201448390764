.shop__table {
  overflow-x: scroll;
  overflow-y: hidden;
  table {
    width: 100%;
    min-width: 1050px;
    tr {
      background: #ffffff;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) !important;
      margin: 7px 0;
      display: flex;
      width: 100%;
      align-items: center;
    }
    th,
    td {
      padding: 16px;
      text-align: left;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
    }
    td {
      font-weight: 300;
    }
    thead {
      tr {
        margin: 12px 0;
        color: #000000;
      }
    }
    tbody {
      tr {
        color: #000000;
      }
      tr:nth-child(even) {
        background-color: #fafafa;
      }
    }
  }
}

.column_product {
  display: flex;
  width: 100%;
  height: 100%;
  .image_product {
    flex: 0 0 77px;
    height: 73px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .detail {
    flex: 1 1 auto;
    font-family: Poppins;
    font-style: normal;
    font-size: 14px;
    text-align: left;
    color: #000000;
    margin-left: 28px;
    > div:nth-child(1) {
      font-weight: 500;
    }
    > div:nth-child(2) {
      white-space: nowrap;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
