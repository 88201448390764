.nav__bar {
  width: 100%;
  margin: 0 auto;
  padding: 16px 8px;

  .button__icon {
    display: flex;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
  }
}
.nav_menu_link {
  padding: 15px !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #4a5463 !important;

  &.active {
    border-bottom: 2px solid #535252;
  }
}
