.order__payment__page {
    .ant-steps {
      max-width: 580px;
      margin: 0 auto 40px auto;
    }
    .ant-steps-item-content {
      width: 120px;
    }
  
    .ant-steps-item-subtitle {
      font-weight: normal;
      font-size: 12px;
      text-align: center;
      color: #979797 !important;
    }
  
    .ant-steps-item-finish .ant-steps-item-icon {
      background: #979797;
      .ant-steps-icon {
        color: white;
      }
    }
  
    .ant-steps-item-active .ant-steps-item-icon {
      background: #979797;
      .ant-steps-icon {
        color: #979797;
        -webkit-transition: color 200ms linear;
        -ms-transition: color 200ms linear;
        transition: color 200ms linear;
      }
    }
  
    .ant-steps-item-icon {
      background: #eeeeee;
      border: none !important;
      .ant-steps-icon {
        color: #eeeeee;
      }
    }
  
    .ant-steps-item-tail {
      padding: 2px 0px 2px 12px;
    }
    .ant-steps-item-tail::after {
      height: 5px;
      background-color: #eeeeee !important;
    }
  }