.modal__cancel {
  text-align: center;
  h1 {
    font-weight: normal;
    font-size: 32px;
    color: #000000;
  }

  p {
    font-weight: normal;
    font-size: 16px;
    color: #000000;
    margin: 10px 0 18px 0;
  }

  .btn_confirm {
    margin-top: 18px;
    height: 38px;
    width: 120px;
    background-color: #535252;
    border-color: #535252;
    border-radius: 0;
    color: white;
  }
}
