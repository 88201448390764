@font-face {
  font-family: Roboto;
  src: url("./Roboto/Roboto-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: Roboto;
  src: url("./Roboto/Roboto-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url("./Roboto/Roboto-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url("./Roboto/Roboto-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url("./Roboto/Roboto-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Poppins;
  src: url("./Poppins/Poppins-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: Poppins;
  src: url("./Poppins/Poppins-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Poppins;
  src: url("./Poppins/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: url("./Poppins/Poppins-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url("./Poppins/Poppins-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: Poppins;
  src: url("./Poppins/Poppins-Bold.ttf");
  font-weight: 700;
}
