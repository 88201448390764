@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

body {
  margin: 0;
  --main-bg-login: #ffc846;
}

Button {
  --main-button: #1DD27E;
}

code {
  font-family: 'Open Sans', sans-serif;
}
