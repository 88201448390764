.order_product__table {
  overflow-x: scroll;
  overflow-y: hidden;
  border: 1px solid #e5e5e5;
  margin-top: 12px;
  .order_table {
    min-width: 500px;
    width: 100%;
    > div {
      display: grid;
      grid-template-columns: 4fr 1fr 1fr 1fr 2fr;
      align-items: center;

      &:nth-child(1) {
        > div {
          background-color: #e5e5e5;
          color: black;
        }
      }
      > div {
        padding: 12px;
        color: #838b9e;
      }
    }

    .body__row:nth-child(odd) {
      background: #f8f7f7;
    }
  }
}

.column_order_product {
  display: flex;
  width: 100%;
  height: 100%;
  .image_product {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .detail {
    font-family: "Poppins";
    font-style: normal;
    font-size: 12px;
    text-align: left;
    color: #838b9e;
    margin-left: 12px;
    > div:nth-child(1) {
      font-weight: 500;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
    }

    .product__detail {
      width: 100%;

      > div {
        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
      }
    }
  }
}
